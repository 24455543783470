import { useMutation } from '@apollo/react-hooks';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { addToCartSuccess } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import t from '@jetshop/intl';
import ProductLink from '@jetshop/ui/ProductLink';
import React, { useContext } from 'react';
import { css } from 'react-emotion';
import { addToCart } from '../Cart/addToCart.gql';
import { OnAddToCart } from '../ProductPage/AddToCart/AddToCartForm';
import { theme } from '../Theme';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingCart, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faShoppingCart, faTags);

const buttonStyle = css`
  background: ${theme.colors.buy};
  text-transform: uppercase;
  font-family: 'Abel', sans-serif;
  font-size: 1.3rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  color: white;
  padding: 0.5rem 1rem;
`;

function QuickBuyButton({ product, productListView }) {
  const { cartId, setCartId } = useContext(CartIdContext);
  const { articleNumber } = product;
  const [add] = useMutation(addToCart, {
    variables: {
      input: {
        cartId,
        articleNumber,
        quantity: 1,
      },
    },
    onCompleted: (data) => addToCartSuccess({ cartId, setCartId })({ data }),
  });

  const { variants, stockStatus } = product;

  if (variants?.values.length > 0 || !stockStatus?.buyable) {
    return (
      <ProductLink
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'inherit',
        }}
        product={product}
      >
        {productListView ? (
          <span className={buttonStyle}>{t('Lägg i varukorgen')}</span>
        ) : (
          <FontAwesomeIcon icon={['fa', 'tags']} />
        )}
      </ProductLink>
    );
  }

  return (
    <OnAddToCart onClick={add}>
      {(props) => (
        <button
          onClick={() => {
            add();
            props.showTarget(true);
          }}
        >
          {productListView ? (
            <span className={buttonStyle}>{t('Lägg i varukorgen')}</span>
          ) : (
            <FontAwesomeIcon icon={['fal', 'shopping-cart']} />
          )}
        </button>
      )}
    </OnAddToCart>
  );
}

export { QuickBuyButton };
