import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';

import theme from '@jetshop/ui/utils/theme';

import Logo from '../../../../img/logo.png';
import MainNav from '../MainNav';
import CartButton from '../../../Cart/CartButton';
import CartFlyout from '../../../Cart/CartFlyout';
import ModelsButton from '../../../ModelSelector/ModelButton';
import ModelFlyout from '../../../ModelSelector/ModelFlyout';

import FilterContext from '@jetshop/core/components/Filters/FilterContext';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FavoritesButton from '../../../ProductList/FavoritesButton';
import { FavoritesFlyout } from '../../../ProductList/Favourites';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useVat } from '../../../Price/VatContext';

import {
  faHeart,
  faShoppingCart,
  faMobileAlt,
  faEnvelope,
  faSearch,
  faBars,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import Checkbox from '@jetshop/ui/Checkbox';
import { ReactComponent as EyewearstoreLogo } from '../../../../svg/Eyewearstore-logo.svg';

import { useChannel } from '../../../../hooks/useChannel';
import { UspIcon } from '../../../StartPage/ExtraDetails';
import t from '@jetshop/intl';

import { Search as DummySearch } from '../Header';

library.add(
  faHeart,
  faShoppingCart,
  faMobileAlt,
  faEnvelope,
  faSearch,
  faBars,
  faTimes
);

const TopBar = styled('div')`
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 40px;
  background: ${theme('colors.lightGrey')};
  &[data-channel='Skoterdelen'] {
    background: #1b1b1a;
    height: 54px;
    a {
      &:hover {
        text-decoration: none;
        color: ${theme('colors.red')};
      }
    }
  }

  a {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 10px 0;
    svg {
      margin-right: 10px;
    }
    &:hover {
      text-decoration: none;
      color: ${theme('colors.eyeWearYellow')};
    }
  }

  ul {
    display: flex;
  }

  ul li {
    position: relative;
    &:hover {
      ul {
        opacity: 1;
        visibility: visible;
      }
    }

    > a {
      padding: 15px 0;
    }
  }

  ul li ul {
    opacity: 0;
    visibility: hidden;
    transition: all, 0.2s;
    position: absolute;
    top: 100%;
    left: -20px;
    display: block;
    z-index: 1;
    min-width: 180px;

    a {
      padding: 10px 20px;
      background: #1c1c1c;
      transition: all, 0.2s;
      color: white;
      &:hover {
        background: #525252;
      }
    }
  }
`;

export const StyledMaxWidth = styled('div')`
  max-width: 80em;
  margin: auto;
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  a {
    text-transform: uppercase;
  }

  > div:last-child {
    a {
      margin-left: 20px;
    }
  }
  [data-channel='Eyewearstore'] & {
    &.we-ahl-usp-maxwidth {
      background: ${theme('colors.lightGrey')};
      [data-sticky-header='true'] & {
        height: 10px;
      }
    }
  }
`;

const TopBarRight = styled('div')`
  display: flex;
  align-items: center;
  font-size: 14px;
  a {
    color: white;
  }
`;
const TopBarLeft = styled('div')`
  display: flex;
  align-items: center;
  font-size: 14px;
  > a {
    margin-right: 20px;
    height: 20px;
    color: white;
  }
`;

const HeaderIconSmall = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  transition: none;
`;

const HeaderContent = styled('div')`
  background: white;
  &[data-channel='Skoterdelen'] {
    background: ${theme('colors.darkGrey')};
    color: #fff;
  }

  .styled-max-width {
    height: 75px;
  }

  transform: translateY(0);
  transition: transform 0.1s ease;

  [data-sticky-header='true'] & {
    position: relative;
    width: 100%;
    z-index: 9999;
    transform: translateY(-54px);
  }

  &[data-channel='Eyewearstore'] {
    [data-sticky-header='true'] & {
      transform: translateY(-35px);
    }
    .header-wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      [data-sticky-header='true'] & {
        padding-top: 1rem;
      }

      .logo-wrapper,
      .header-icons-wrapper {
        flex: 1;
      }

      .search-input-wrapper {
        .search-container-wrapper {
          width: 100%;
        }
      }

      .header-icons-wrapper {
        justify-content: flex-end;
        display: flex;
        align-items: center;
      }
    }
  }

  .search-container-wrapper {
    margin-top: 5px;
    [data-channel='Skoterdelen'] & {
      background: transparent;
      .inner-search-wrapper {
        width: 55%;
      }
    }
    .inner-search-wrapper {
      [data-channel='Eyeewearstore'] & {
        width: 100%;
      }
    }
  }
`;

const HeaderLeft = styled('div')`
  display: flex;
  align-items: center;
  flex: 2;
`;
const HeaderRight = styled('div')`
  display: flex;
  align-items: center;
`;

const StoreName = styled('div')`
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  margin-top: 8px;
  color: inherit;
  margin-right: 65px;
  max-width: 300px;
  padding-top: 0.5rem;

  [data-channel='Eyewearstore'] & {
    max-width: 240px;
    margin-right: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
    text-transform: lowercase;
    svg {
      width: 100%;
    }
  }
  img {
    width: 100%;
  }

  ${theme('below.md')} {
    margin: auto;
    width: 100%;
    max-width: 200px;
    padding: 10px 10px 5px 10px;
    img {
      max-width: 100%;
    }
  }
`;

export const StyledCheckBox = styled(Checkbox)`
  width: 120px;
  display: inline-block;
  vertical-align: middle;
  font-size: 11px;
  &:focus {
    outline: none !important;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    outline: none !important;
    border: 1px solid #fff !important;
    background: none !important;
    svg {
      position: relative;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      left: 50%;
    }
  }
`;

const dropdown = {
  '/kopvillkor': 'Köpvillkor',
  '/info-tips': 'Info & Tips',
  '/om-oss': 'Om oss',
  '/butiken': 'Butiken',
};

export function TopBarLinks() {
  const { loggedIn } = useAuth();

  return (
    <ul>
      <li>
        <Link to="/kundtjanst">Kundtjänst</Link>
        <ul>
          {Object.keys(dropdown).map((link) => (
            <li key={link}>
              <Link to={link}>{dropdown[link]}</Link>
            </li>
          ))}
          <li>
            <a href="https://www.youtube.com/channel/UCuApKpX583aNATtClR71-zA" target="_blank" rel="noopener noreferrer">Skoterdelen TV</a>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/aterforsaljare">Återförsäljare</Link>
      </li>
      <li>
        <Link to="/login">{loggedIn ? 'Mina sidor' : 'Logga in'}</Link>
      </li>
    </ul>
  );
}

const UspBarWrappper = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  background: ${theme('colors.lightGrey')};
  && li {
    display: flex;
    align-items: center;
    color: white;
    font-size: 1rem;
    ${theme('above.md')} {
      margin: 0 5.5rem 0 3.5rem;
    }
    ${theme('below.md')} {
      font-size: 0.75rem;
    }
    ${theme('below.sm')} {
      font-size: 0.65rem;
    }
    svg {
      color: ${theme('colors.eyeWearYellow')};
      width: 0.475em;
    }
  }
  ${theme('below.md')} {
    justify-content: space-evenly;
  }
`;

export function WeAhlUsp() {
  return (
    <StyledMaxWidth className="we-ahl-usp-maxwidth">
      <UspBarWrappper>
        <li>
          <UspIcon icon={['fal', 'check']} />
          {t('FRI FRAKT ÖVER 500 KR')}
        </li>
        <li>
          <UspIcon icon={['fal', 'check']} />
          {t('PRISGARANTI')}
        </li>
        <li>
          <UspIcon icon={['fal', 'check']} />
          {t('SNABB LEVERANS')}
        </li>
      </UspBarWrappper>
    </StyledMaxWidth>
  );
}

function DesktopHeader({ result }) {
  const [incVat, setIncVat] = useVat();
  const { isWeAhl } = useChannel();

  return isWeAhl ? (
    <>
      <HeaderContent data-channel="Eyewearstore">
        <StyledMaxWidth style={{ display: 'block' }}>
          <div className="header-wrapper">
            <div className="logo-wrapper">
              <StoreName>
                <Link to="/">
                  <EyewearstoreLogo />
                </Link>
              </StoreName>
            </div>
            <div className="search-input-wrapper">
              <DummySearch className="search-container-wrapper" isWeAhl={isWeAhl} />
            </div>
            <div className="header-icons-wrapper">
              <FavoritesButton />
              <CartButton />
            </div>
          </div>
          <MainNav categories={result} />
        </StyledMaxWidth>
      </HeaderContent>
      <CartFlyout />
      <FavoritesFlyout />
      <ModelFlyout />
    </>
  ) : (
    <>
      <TopBar data-channel="Skoterdelen">
        <StyledMaxWidth>
          <TopBarLeft>
            <Link to="/kundtjanst">
              <HeaderIconSmall icon={['fal', 'mobile-alt']} />
              0687-58 10 30
            </Link>
            <a href="mailto:support@skoterdelen.com">
              <HeaderIconSmall icon={['fal', 'envelope']} />{' '}
              support@skoterdelen.com
            </a>
          </TopBarLeft>
          <TopBarRight>
            <TopBarLinks />
          </TopBarRight>
        </StyledMaxWidth>
      </TopBar>
      <HeaderContent data-channel="Skoterdelen">
        <StyledMaxWidth className="styled-max-width">
          <HeaderLeft>
            <StoreName>
              <Link to="/">
                <img alt="Skoterdelen" src={Logo} />
              </Link>
            </StoreName>
            <DummySearch className="search-container-wrapper" />
          </HeaderLeft>
          <HeaderRight>
            <StyledCheckBox
              label={
                incVat ? 'Visar priser inkl. moms' : 'Visar priser exkl. moms'
              }
              onChange={setIncVat}
              checked={incVat}
            />
            <FilterContext.Consumer>
              {({ activeFilters }) => (
                <ModelsButton activeFilters={activeFilters} />
              )}
            </FilterContext.Consumer>
            <FavoritesButton />
            <CartButton />
          </HeaderRight>
        </StyledMaxWidth>
        <MainNav categories={result} />
        <CartFlyout />
        <FavoritesFlyout />
        <ModelFlyout />
      </HeaderContent>
    </>
  );
}

export default DesktopHeader;
