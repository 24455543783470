import Image from '@jetshop/ui/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import ProductImage from '@jetshop/ui/ProductList/ProductImage';
import theme from '@jetshop/ui/utils/theme';
import { css } from 'emotion';
import React, { Component } from 'react';
import styled from 'react-emotion';
import ImageMissing from '../../img/image-missing.jpg';
import { Price } from '../Price';
import PricePromotion from '../Price/PricePromotion';
import AddFavoriteButton from '../ProductList/AddFavoriteButton';
import { QuickBuyButton } from '../ui/QuickBuyButton';
import ProductRatings from '../Yotpo/ProductRating';

import { Query } from 'react-apollo';
import InfoIconHover from '../../icons/produktbox-ikoner-35x35px_info-mover.png';
import InfoIcon from '../../icons/produktbox-ikoner-35x35px_Info-std.png';
import ListIconHover from '../../icons/produktbox-ikoner-35x35px_modell-lista-mover.png';
import ListIcon from '../../icons/produktbox-ikoner-35x35px_Modell-lista-std.png';
import CustomFields from './CustomFields.gql';

import { Above } from '@jetshop/ui/Breakpoints';
import Badges from '@jetshop/ui/ProductList/Badges';
import { VatContext } from '../Price/VatContext';

const yearRegex = /\b(19|20)\d{2}\b/;

export const ProductWrapper = styled('div')`
  width: 50%;
  margin-bottom: 30px;
  ${theme('above.md')} {
    width: 33.333%;
  }
  ${theme('above.lg')} {
    width: 20%;
  }
`;
export const ProductInnerWrapper = styled('div')`
  position: relative;
  background: white;
  height: 100%;
  width: 100%;
  img {
    max-width: 100%;
    height: auto;
  }
`;
export const ProductBlockLink = styled(ProductLink)`
  text-decoration: none;
  color: inherit;
  display: block;
  background-color: white;
  position: relative;
  margin: 0;
`;
export const ProductDetails = styled('div')`
  background-color: white;
  padding: 10px;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  padding-bottom: 45px;

  /* @-moz-document url-prefix() {
    padding-bottom: 10px;
  } */

  > div > div {
    font-weight: bold;
    letter-spacing: -0.5px;
    font-size: 1.2rem;
    color: ${theme('colors.red')};
    + div {
      color: #636363;
      font-size: 13px;
    }
  }
`;

export const Name = styled('div')`
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 5px;
  min-height: 32px;
  /* @-moz-document url-prefix() {
    min-height: 90px;
  } */
  ${theme('below.md')} {
    font-size: 14px;
  }
`;
export const StyledProductImage = styled(ProductImage)`
  width: 100%;
  margin: auto;
  background-image: url(${ImageMissing});
  img + div {
    display: none;
  }
`;

const BottomSection = styled('section')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px 10px;
  height: 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  ${Price.Old} {
    color: #636363;
    font-size: 13px;
    font-weight: 400;
  }
`;

const BottomButtons = styled('div')`
  display: flex;
  align-items: center;
  img {
    width: 35px !important;
    height: 35px !important;
  }
  button,
  a {
    width: 35px;
    height: 35px;
    padding: 0;
    background: none;
    color: #00b55b !important;
    &:hover {
      background: #00b55b !important;
      color: #fff !important;

      svg {
        color: #fff;
      }
    }
  }

  table {
    width: 100%;
  }
  ${theme('below.md')} {
    .extra-buttons {
      display: none;
    }
  }
`;

const StyledPrice = styled(Price)`
  font-weight: bold;
  letter-spacing: -0.5px;
  font-size: 1.2rem;
  color: black;
  height: 30px;
  text-align: left;
  ${Price.New} {
    color: ${theme('colors.red')};
  }
`;

const ToolTip = styled('div')`
  position: absolute;
  background: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  z-index: 999;
  right: 0;
  top: 50%;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3;
  font-size: 14px;
  max-height: 500px;
  overflow-y: auto;
  .hide {
    display: none;
  }
`;

const ModelTable = styled('table')`
  width: 100%;
  tr:nth-child(even) {
    background: #f8f8f8;
  }
  th {
    font-weight: 900;
  }
  th,
  td {
    padding: 5px;
  }
`;

class ModelList extends React.Component {
  state = {
    items: [],
  };

  filterByParent = (items, parentId) => {
    return items.filter(function (item) {
      return item.parentId === parentId;
    });
  };

  getItemById = (id, items) => {
    for (var i = 0; i < items.length; i++) {
      if (items[i].id === id) {
        return items[i];
      }
    }
  };

  componentDidMount() {
    var items = this.props.items;
    var newItems = [];
    for (var i = 0; i < items.length; i++) {
      if (items[i].parentId === null) {
      } else {
        if (this.getItemById(items[i].parentId, items).title === 'Märke') {
        } else {
          var parent = this.getItemById(items[i].parentId, items);
          var grandParent = this.getItemById(parent.parentId, items);

          var newItem = {
            model: items[i].value,
            year: parent.value,
            brand: grandParent?.value,
          };
          newItems.push(newItem);
        }
      }
    }
    newItems.sort(function (a, b) {
      if (a.brand > b.brand) {
        return 1;
      } else if (a.brand < b.brand) {
        return -1;
      }

      if (a.model > b.model) {
        return 1;
      } else if (a.model < b.model) {
        return -1;
      }

      if (a.year < b.year) {
        return -1;
      } else if (a.year > b.year) {
        return 1;
      }
      return 1;
    });
    this.setState({ items: newItems });
  }

  render() {
    return (
      <ModelTable>
        <tbody>
          {this.state.items.map((item, index) => {
            if (!yearRegex.test(item.year)) {
              return <></>;
            }
            if (index === 0) {
              return (
                <>
                  <tr>
                    <th>{item.brand}</th>
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>{item.model}</td>
                    <td>{item.year}</td>
                  </tr>
                </>
              );
            }
            if (item.brand !== this.state.items[index - 1].brand) {
              return (
                <tr>
                  <th>{item.brand}</th>
                  <td />
                  <td />
                </tr>
              );
            }
            return (
              <tr>
                <td>{item.model}</td>
                <td>{item.year}</td>
              </tr>
            );
          })}
        </tbody>
      </ModelTable>
    );
  }
}

class GridProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
      showModels: false,
      showButtons: false,
    };
  }
  componentDidMount() {
    if (typeof document !== 'undefined') {
      document.addEventListener('mousedown', this.handleClick, false);
    }
  }
  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      document.removeEventListener('mousedown', this.handleClick, false);
    }
  }
  handleClick = (e) => {
    if (document.getElementById('info-' + this.props.product.id)) {
      if (
        !document
          .getElementById('info-' + this.props.product.id)
          .contains(e.target)
      ) {
        this.setState({ showInfo: false });
      }
    }
    if (document.getElementById('models-' + this.props.product.id)) {
      if (
        !document
          .getElementById('models-' + this.props.product.id)
          .contains(e.target)
      ) {
        this.setState({ showModels: false });
      }
    }
  };
  showInfo() {
    this.setState({ showInfo: !this.state.showInfo });
  }
  showModels() {
    this.setState({ showModels: !this.state.showModels });
  }
  modelsExists(customFields) {
    for (var i = 0; i < customFields.length; i++) {
      if (customFields[i].title?.toLowerCase() === 'passar modeller') {
        return true;
      }
    }
  }
  render() {
    const {
      product,
      categoryPath,
      loading,
      className = 'product',
      onClick,
      hash,
    } = this.props;

    const { showInfo, showModels } = this.state;
    if (product.categories) {
      var inCat = product.categories.filter(
        (c) => c.primaryRoute.id === categoryPath.id
      );
      if (inCat.length < 1) {
        return null;
      }
    }

    // if (!product.variants || (product.variants && !product.variants.options)) {
    //   return null;
    // }

    return (
      <ProductWrapper
        onClick={onClick}
        data-testid="product"
        className={className}
        onMouseOver={() => this.setState({ showButtons: true })}
        onMouseLeave={() => this.setState({ showButtons: false })}
      >
        <ProductInnerWrapper>
          <ProductBlockLink
            product={product}
            categoryPath={categoryPath}
            hash={hash}
            style={{ opacity: loading ? '0.5' : '1' }}
          >
            {product.images.length > 0 ? (
              <>
                <Image
                  className="product-image"
                  aspect="1:1"
                  sizes={[1 / 3, 1 / 3, 1 / 2, 1 / 4]}
                  src={product.images[0].url}
                  badges={product.badges}
                />
                <Badges badges={product.badges} />
              </>
            ) : (
              <img src={ImageMissing} alt={product.name} loading="lazy" />
            )}
          </ProductBlockLink>
          <AddFavoriteButton product={product} />
          {product.price &&
            product.previousPrice.incVat !== product.price.incVat && (
              <PricePromotion
                className="price-promotion"
                previousPrice={product.previousPrice.incVat}
                price={product.price.incVat}
              />
            )}
          <ProductDetails className="product-details">
            <ProductBlockLink
              product={product}
              categoryPath={categoryPath}
              className={
                loading &&
                css`
                  opacity: 0.5;
                `
              }
            >
              <Name>
                <span
                  dangerouslySetInnerHTML={{
                    __html: product.name,
                  }}
                />
                <ProductRatings product={product} />
              </Name>
            </ProductBlockLink>
          </ProductDetails>
          <BottomSection>
            <VatContext.Consumer>
              {({ incVat }) => (
                <StyledPrice
                  includeVat={incVat}
                  price={product.price}
                  previousPrice={product.previousPrice}
                  className="price-wrapper"
                />
              )}
            </VatContext.Consumer>
            <Above breakpoint="md">
              {(matches) =>
                matches ? (
                  <BottomButtons onClick={(e) => e.stopPropagation()}>
                    {this.state.showButtons && product.customFields && (
                      <div className="extra-buttons">
                        {this.modelsExists(product.customFields) && (
                          <img
                            onClick={() => this.showModels()}
                            src={ListIcon}
                            onMouseOver={(e) =>
                              (e.currentTarget.src = ListIconHover)
                            }
                            onMouseOut={(e) => (e.currentTarget.src = ListIcon)}
                            alt="Visa modeller"
                          />
                        )}
                      </div>
                    )}
                    {this.state.showButtons && (
                      <img
                        onClick={() => this.showInfo()}
                        src={InfoIcon}
                        onMouseOver={(e) =>
                          (e.currentTarget.src = InfoIconHover)
                        }
                        onMouseOut={(e) => (e.currentTarget.src = InfoIcon)}
                        alt="Visa information"
                      />
                    )}
                    {showInfo && (
                      <ToolTip
                        id={'info-' + product.id}
                        dangerouslySetInnerHTML={{
                          __html: product.description,
                        }}
                        onClick={() => this.showInfo()}
                      />
                    )}
                    {showModels && (
                      <Query
                        query={CustomFields}
                        variables={{ articleNumber: product.articleNumber }}
                      >
                        {({ loading, error, data }) => {
                          if (loading) return null;
                          if (error) return `Error!: ${error}`;
                          return (
                            <React.Fragment>
                              {data.product.customFields &&
                                data.product.customFields.map((field, i) => (
                                  <React.Fragment key={i}>
                                    {field?.title?.toLowerCase() ===
                                      'passar modeller' && (
                                      <ToolTip
                                        id={'models-' + product.id}
                                        onClick={() => this.showModels()}
                                      >
                                        <ModelList
                                          customFields={product.customFields}
                                          items={field.items}
                                          field={field}
                                        />
                                      </ToolTip>
                                    )}
                                  </React.Fragment>
                                ))}
                            </React.Fragment>
                          );
                        }}
                      </Query>
                    )}
                    <QuickBuyButton
                      product={product}
                      hasVariants={
                        product.hasVariants || !product?.stockStatus?.buyable
                      }
                    />
                  </BottomButtons>
                ) : (
                  <BottomButtons>
                    <QuickBuyButton
                      product={product}
                      hasVariants={
                        product.hasVariants || !product?.stockStatus?.buyable
                      }
                    />
                  </BottomButtons>
                )
              }
            </Above>
          </BottomSection>
        </ProductInnerWrapper>
      </ProductWrapper>
    );
  }
}

export default GridProduct;
