import React from 'react';
import styled from 'react-emotion';

import theme from '@jetshop/ui/utils/theme';
import { Price } from '../Price';
import RemoveFromCart from '@jetshop/core/components/Mutation/RemoveFromCart';
import DecrementQuantity from '@jetshop/core/components/Mutation/DecrementQuantity';
import IncrementQuantity from '@jetshop/core/components/Mutation/IncrementQuantity';

import CartQuery from './CartQuery.gql';

import Image from '@jetshop/ui/Image';

import {
  StockStatusWrapper,
  HiddenContent,
  StockStatus,
} from '../ProductPage/ProductPage';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faTimes } from '@fortawesome/pro-light-svg-icons';
import ProductLink from '@jetshop/ui/ProductLink';
import { VatContext } from '../Price/VatContext';

import {
  useStocklevelCalc,
  availableStatus,
} from '../ProductPage/useStocklevelCalc';

library.add(faShoppingCart, faTimes);

const Wrapper = styled('div')`
  display: flex;
  background: white;
  margin-bottom: 0.5rem;
  align-items: center;
  ${theme('below.md')} {
    margin-bottom: 0.5rem;
  }
`;

const ProductItemImage = styled('div')`
  flex: 1;
  position: relative;
  ${theme('below.md')} {
    flex: 2;
  }
`;

const ProductName = styled('h2')`
  color: black;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: ${theme('fontFamilies.body')};
  font-weight: normal;
  ${theme('below.md')} {
    font-size: 15px;
    margin-bottom: 0.5em;
  }
`;

const ProductArtNo = styled('span')`
  display: block;
  color: #a5a5a5;
  font-size: 1rem;
  ${theme('below.md')} {
    margin-bottom: 0.5em;
  }
`;

const ProductDetail = styled('section')`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  flex: 5;
  padding-left: 15px;
`;

const ItemDetails = styled('div')`
  flex: 3;
  ${theme('below.md')} {
    flex: none;
    width: 100%;
  }
`;

const LinePrice = styled(Price)`
  font-weight: 600;
  font-family: ${theme('fontFamilies.heavy')};
  text-align: center;
  ${theme('below.md')} {
    text-align: left;
    font-size: 0.85rem;
    margin: 3px 0;
  }

  ${Price.New} {
    color: #ce3233;
    font-size: 1.2rem;
  }
`;

const PriceWrapper = styled('div')`
  flex: 2;
  text-align: center;
  ${theme('below.md')} {
    text-align: left;
    font-size: 0.85rem;
  }

  ${StockStatusWrapper} {
    ${theme('below.md')} {
      margin: 0;
    }
    span {
      font-size: 16px;
      ${theme('below.md')} {
        font-size: 14px;
        text-align: left;
        margin-bottom: 10px;
        position: absolute;
        white-space: nowrap;
      }
    }
  }

  ${HiddenContent} {
    font-size: 11px;
    width: 100%;
    padding: 10px 5px;
    ${theme('below.md')} {
      display: none;
    }
  }
`;

const AdjustQty = styled('div')`
  display: flex;
  grid-area: bottomleft;
  /* Plus/minus buttons */
  button {
    background: #f2f2f2;
    color: black;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    :focus {
      outline: none;
    }
  }
  /* Count */
  span {
    margin: 0 0.5rem;
    display: inline-block;
    ${theme('below.md')} {
      line-height: 20px;
    }
  }
`;

const RemoveItem = styled('div')`
  text-align: center;
  flex: 1;
  button {
    background: none;
  }
`;
const CartRemoveIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: #a5a5a5;
  margin-top: 2px;
`;
const StyledImage = styled(Image)`
  padding-top: 100%;
`;

const CartItem = ({ item, className = '' }) => {
  const { productStockStatus } = useStocklevelCalc({
    product: item.variant ? item.variant : item.product,
  });
  return (
    <Wrapper className={className}>
      <ProductItemImage>
        <StyledImage
          src={item.product.images[0] && item.product.images[0].url}
          alt={item.product.name}
        />
      </ProductItemImage>
      <ProductDetail>
        <ItemDetails>
          <ProductLink product={item.product}>
            <ProductName>{item.product.name}</ProductName>
          </ProductLink>
          <ProductArtNo>
            Art.nr: {(item.variant || item.product).articleNumber}
          </ProductArtNo>
        </ItemDetails>

        <PriceWrapper>
          <VatContext.Consumer>
            {({ incVat }) => (
              <LinePrice
                includeVat={incVat}
                price={item.variant ? item.variant.price : item.product.price}
                previousPrice={
                  item.variant
                    ? item.variant.previousPrice
                    : item.product.previousPrice
                }
              />
            )}
          </VatContext.Consumer>

          <div>
            {item?.variant ? (
              <StockStatus stockStatus={productStockStatus} />
            ) : (
              <StockStatus stockStatus={productStockStatus} />
            )}
          </div>
        </PriceWrapper>

        <AdjustQty>
          <DecrementQuantity cartQuery={CartQuery}>
            {(decrementQuantity) => (
              <button
                disabled={item.quantity === 1}
                onClick={() =>
                  item.quantity !== 1 && decrementQuantity(item.id)
                }
              >
                -
              </button>
            )}
          </DecrementQuantity>
          <span>{item.quantity}</span>
          <IncrementQuantity cartQuery={CartQuery}>
            {(incrementQuantity) => (
              <button onClick={() => incrementQuantity(item.id)}>+</button>
            )}
          </IncrementQuantity>
        </AdjustQty>

        <RemoveItem>
          <RemoveFromCart cartQuery={CartQuery}>
            {(removeFromCart) => (
              <button onClick={() => removeFromCart(item.id, item.product)}>
                <CartRemoveIcon icon={['fal', 'times']} />
              </button>
            )}
          </RemoveFromCart>
        </RemoveItem>
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
